@use "sass:meta";
@use "colors";

:root {
  @each $name, $hex in meta.module-variables("colors") {
    // These ignores are currently required because of this bug:
    // https://github.com/framer/motion/issues/546
    // prettier-ignore
    --#{$name}:#{$hex};
    // prettier-ignore
    --#{$name}-rgb:#{red($hex), green($hex), blue($hex)};
  }

  --font-family-display: "PT Serif", sans-serif;
  --font-family-body: "PT Sans", sans-serif;

  --font-size-S: 16px;
  --font-size-M: 19px;
  --font-size-L: 23px;
  --font-size-XL: 28px;
  --font-size-XXL: 34px;
  --font-size-XXXL: 38px;

  --rounding: 6px;

  --sunken: inset 0 0 4px 0 rgba(0, 0, 0, 0.25);
  --raised-1: 0px 1px 2px rgba(0, 0, 0, 0.25);
  --raised-2: 0px 1px 4px rgba(0, 0, 0, 0.35);

  --transition: 0.05s ease-out;
}

// Overrides
.asteriskField {
  display: none;
}

// Styles
body {
  background-color: var(--gray-6);
}
