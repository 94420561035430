@import "../brand.scss";
@import "../layout.scss";
@import "../vendors.scss";

// Variables
$primary-accent-color: #3c49fa;
$primary-accent-border-color: #2f3efa;

$secondary-accent-color: #e98407;
$secondary-accent-border-color: #dd7d07;

$tertiary-accent-color: #000219;
$tertiary-accent-border-color: #00010d;

$success-color: #4d8f0b;

body {
  line-height: 1.3;
}
.nav-link {
  font-weight: 400;
  margin-left: 8px;
  margin-right: 8px;
  font-family: "PT Sans", Roboto, Arial;
}

// Brand Theme
.btn-brand-primary {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-brand-primary:hover {
  color: #fff;
  background-color: $primary-accent-color;
  border-color: $primary-accent-border-color;
}

.btn-brand-primary:focus,
.btn-brand-primary.focus {
  box-shadow: 0 0 0 3px rgba(97, 108, 251, 0.5);
}

.btn-brand-primary.disabled,
.btn-brand-primary:disabled {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-brand-primary:active,
.btn-brand-primary.active,
.show > .btn-brand-primary.dropdown-toggle {
  background-color: $primary-accent-color;
  background-image: none;
  border-color: $primary-accent-border-color;
}

.btn-brand-secondary {
  color: var(--secondary);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-brand-secondary:hover {
  color: var(--secondary);
  background-color: $secondary-accent-color;
  border-color: $secondary-accent-border-color;
}

.btn-brand-secondary:focus,
.btn-brand-secondary.focus {
  box-shadow: 0 0 0 3px rgba(250, 158, 44, 0.5);
}

.btn-brand-secondary.disabled,
.btn-brand-secondary:disabled {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-brand-secondary:active,
.btn-brand-secondary.active,
.show > .btn-brand-secondary.dropdown-toggle {
  background-color: $secondary-accent-color;
  background-image: none;
  border-color: $secondary-accent-border-color;
}

.btn-brand-tertiary {
  color: #fff;
  background-color: var(--tertiary);
  border-color: var(--tertiary);
}

.btn-brand-tertiary:hover {
  color: #fff;
  background-color: $tertiary-accent-color;
  border-color: $tertiary-accent-border-color;
}

.btn-brand-tertiary:focus,
.btn-brand-tertiary.focus {
  box-shadow: 0 0 0 3px rgba(1, 5, 63, 0.5);
}

.btn-brand-tertiary.disabled,
.btn-brand-tertiary:disabled {
  background-color: var(--tertiary);
  border-color: var(--tertiary);
}

.btn-brand-tertiary:active,
.btn-brand-tertiary.active,
.show > .btn-brand-tertiary.dropdown-toggle {
  background-color: $tertiary-accent-color;
  background-image: none;
  border-color: $tertiary-accent-border-color;
}

.btn-outline-brand-primary {
  color: var(--primary);
  background-color: transparent;
  background-image: none;
  border-color: var(--primary);
}

.btn-outline-brand-primary:hover {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-brand-primary:focus,
.btn-outline-brand-primary.focus {
  box-shadow: 0 0 0 3px rgba(97, 108, 251, 0.5);
}

.btn-outline-brand-primary.disabled,
.btn-outline-brand-primary:disabled {
  color: var(--primary);
  background-color: transparent;
}

.btn-outline-brand-primary:active,
.btn-outline-brand-primary.active,
.show > .btn-outline-brand-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-brand-secondary {
  color: var(--secondary);
  background-color: transparent;
  background-image: none;
  border-color: var(--secondary);
}

.btn-outline-brand-secondary:hover {
  color: #fff;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-outline-brand-secondary:focus,
.btn-outline-brand-secondary.focus {
  box-shadow: 0 0 0 3px rgba(250, 158, 44, 0.5);
}

.btn-outline-brand-secondary.disabled,
.btn-outline-brand-secondary:disabled {
  color: var(--secondary);
  background-color: transparent;
}

.btn-outline-brand-secondary:active,
.btn-outline-brand-secondary.active,
.show > .btn-outline-brand-secondary.dropdown-toggle {
  color: #fff;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-outline-brand-tertiary {
  color: var(--tertiary);
  background-color: transparent;
  background-image: none;
  border-color: var(--tertiary);
}

.btn-outline-brand-tertiary:hover {
  color: #fff;
  background-color: var(--tertiary);
  border-color: var(--tertiary);
}

.btn-outline-brand-tertiary:focus,
.btn-outline-brand-tertiary.focus {
  box-shadow: 0 0 0 3px rgba(1, 5, 63, 0.5);
}

.btn-outline-brand-tertiary.disabled,
.btn-outline-brand-tertiary:disabled {
  color: var(--tertiary);
  background-color: transparent;
}

.btn-outline-brand-tertiary:active,
.btn-outline-brand-tertiary.active,
.show > .btn-outline-brand-tertiary.dropdown-toggle {
  color: #fff;
  background-color: var(--tertiary);
  border-color: var(--tertiary);
}

.btn-link {
  font-weight: normal;
  color: var(--primary);
  border-radius: 0;
}

.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link:disabled {
  background-color: transparent;
}

.btn-link,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
  box-shadow: none;
}

.btn-link:hover {
  border-color: transparent;
}

.btn-link:focus,
.btn-link:hover {
  color: #1b3257;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link:disabled {
  color: #868e96;
}

.btn-link:disabled:focus,
.btn-link:disabled:hover {
  text-decoration: none;
}

.badge-brand-primary {
  color: #fff;
  background-color: var(--primary);
}

.badge-brand-primary[href]:focus,
.badge-brand-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: $primary-accent-border-color;
}

.badge-brand-secondary {
  color: var(--secondary);
  background-color: var(--secondary);
}

.badge-brand-secondary[href]:focus,
.badge-brand-secondary[href]:hover {
  color: var(--secondary);
  text-decoration: none;
  background-color: $secondary-accent-border-color;
}

.badge-brand-tertiary {
  color: #fff;
  background-color: var(--tertiary);
}

.badge-brand-tertiary[href]:focus,
.badge-brand-tertiary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1a1a1a;
}

.alert-brand-primary {
  color: #172b4b;
  background-color: #d5dde9;
  border-color: #c4cfe0;
}

.alert-brand-primary hr {
  border-top-color: #b3c1d7;
}

.alert-brand-primary .alert-link {
  color: #0b1524;
}

.alert-brand-secondary {
  color: #814f10;
  background-color: #feead2;
  border-color: #fde2c0;
}

.alert-brand-secondary hr {
  border-top-color: #fcd7a7;
}

.alert-brand-secondary .alert-link {
  color: #54330a;
}

.alert-brand-tertiary {
  color: #1b1b1b;
  background-color: #d6d6d6;
  border-color: #c6c6c6;
}

.alert-brand-tertiary hr {
  border-top-color: #b9b9b9;
}

.alert-brand-tertiary .alert-link {
  color: #020202;
}

.list-group-item-brand-primary {
  color: #172b4b;
  background-color: #c4cfe0;
}

a.list-group-item-brand-primary,
button.list-group-item-brand-primary {
  color: #172b4b;
}

a.list-group-item-brand-primary:focus,
a.list-group-item-brand-primary:hover,
button.list-group-item-brand-primary:focus,
button.list-group-item-brand-primary:hover {
  color: #172b4b;
  background-color: #b3c1d7;
}

a.list-group-item-brand-primary.active,
button.list-group-item-brand-primary.active {
  color: #fff;
  background-color: #172b4b;
  border-color: #172b4b;
}

.list-group-item-brand-secondary {
  color: #814f10;
  background-color: #fde2c0;
}

a.list-group-item-brand-secondary,
button.list-group-item-brand-secondary {
  color: #814f10;
}

a.list-group-item-brand-secondary:focus,
a.list-group-item-brand-secondary:hover,
button.list-group-item-brand-secondary:focus,
button.list-group-item-brand-secondary:hover {
  color: #814f10;
  background-color: #fcd7a7;
}

a.list-group-item-brand-secondary.active,
button.list-group-item-brand-secondary.active {
  color: #fff;
  background-color: #814f10;
  border-color: #814f10;
}

.list-group-item-brand-tertiary {
  color: #1b1b1b;
  background-color: #c6c6c6;
}

a.list-group-item-brand-tertiary,
button.list-group-item-brand-tertiary {
  color: #1b1b1b;
}

a.list-group-item-brand-tertiary:focus,
a.list-group-item-brand-tertiary:hover,
button.list-group-item-brand-tertiary:focus,
button.list-group-item-brand-tertiary:hover {
  color: #1b1b1b;
  background-color: #b9b9b9;
}

a.list-group-item-brand-tertiary.active,
button.list-group-item-brand-tertiary.active {
  color: #fff;
  background-color: #1b1b1b;
  border-color: #1b1b1b;
}

.bg-brand-primary {
  background-color: var(--primary) !important;
}

a.bg-brand-primary:focus,
a.bg-brand-primary:hover {
  background-color: $primary-accent-border-color !important;
}

.bg-brand-secondary {
  background-color: var(--secondary) !important;
}

a.bg-brand-secondary:focus,
a.bg-brand-secondary:hover {
  background-color: $secondary-accent-border-color !important;
}

.bg-brand-tertiary {
  background-color: var(--tertiary) !important;
}

a.bg-brand-tertiary:focus,
a.bg-brand-tertiary:hover {
  background-color: #1a1a1a !important;
}

.border-brand-primary {
  border-color: var(--primary) !important;
}

.border-brand-secondary {
  border-color: var(--secondary) !important;
}

.border-brand-tertiary {
  border-color: var(--tertiary) !important;
}

.text-brand-primary {
  color: var(--primary) !important;
}

a.text-brand-primary:focus,
a.text-brand-primary:hover {
  color: $primary-accent-border-color !important;
}

.text-brand-secondary {
  color: var(--secondary) !important;
}

a.text-brand-secondary:focus,
a.text-brand-secondary:hover {
  color: $secondary-accent-border-color !important;
}

.text-brand-tertiary {
  color: var(--tertiary) !important;
}

a.text-brand-tertiary:focus,
a.text-brand-tertiary:hover {
  color: #1a1a1a !important;
}

.table-brand-primary,
.table-brand-primary > th,
.table-brand-primary > td {
  background-color: #c4cfe0;
}

.table-hover .table-brand-primary:hover {
  background-color: #b3c1d7;
}

.table-hover .table-brand-primary:hover > td,
.table-hover .table-brand-primary:hover > th {
  background-color: #b3c1d7;
}

.table-brand-secondary,
.table-brand-secondary > th,
.table-brand-secondary > td {
  background-color: #fde2c0;
}

.table-hover .table-brand-secondary:hover {
  background-color: #fcd7a7;
}

.table-hover .table-brand-secondary:hover > td,
.table-hover .table-brand-secondary:hover > th {
  background-color: #fcd7a7;
}

.table-brand-tertiary,
.table-brand-tertiary > th,
.table-brand-tertiary > td {
  background-color: #c6c6c6;
}

.table-hover .table-brand-tertiary:hover {
  background-color: #b9b9b9;
}

.table-hover .table-brand-tertiary:hover > td,
.table-hover .table-brand-tertiary:hover > th {
  background-color: #b9b9b9;
}

.login-container {
  width: 350px;
  max-width: 100%;
  margin: 3rem auto;
  padding: 0 1rem;
}

form input[type="email"] {
  text-transform: lowercase;
}

#assess-form-review {
  .custom-control-label {
    font-weight: normal;
  }
  form label {
    font-weight: bold;
  }
}

.thin-progress-bar-wrapper {
  height: 5px;
  border-radius: 0;
}

.thin-progress-bar {
  background-color: $success-color;
}
