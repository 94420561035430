@use "sass:meta";
@use "breakpoints";

#main-container {
  min-height: 100vh; /* will cover the 100% of viewport */
  display: flex;
  flex-flow: column nowrap;
}

#authenticated-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 0;
  flex-wrap: wrap;

  .logo {
    margin-left: 1rem;
  }

  .action-links {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 1em;
    }
  }
}

@media (max-width: breakpoints.$sm) {
  #authenticated-nav {
    position: sticky;
    top: 0;
    background: var(--gray-6);
    z-index: 100;
  }

  #authenticated-nav .mobile-menu.reactified {
    position: relative;
    width: 100%;

    &.open .menu-contents {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
      max-height: 1000px;
      transition: max-height 0.25s ease-in;
    }

    .menu-contents {
      background-color: var(--gray-6);
      margin-bottom: 5px;
      margin-top: 5px;
      max-height: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      transition: max-height 0.25s ease-in-out;
      width: 100%;
      z-index: 99;

      #react-mobile-menu-portal-root {
        margin-left: auto;
        margin-right: auto;
        max-width: 400px;
        padding-bottom: 1rem;
      }
    }

    .action-links {
      flex-direction: column;
      padding-top: 1.5rem;

      > *:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}

@include meta.load-css("~react-notifications-component/dist/theme");

#notifications-root {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 20;
}
.notification__item {
  color: var(--notif-fg) !important;
  background-color: var(--notif-bg);
  border-color: var(--notif-mid);
  box-shadow: var(--raised-2);
}
.notification__item .notification__title {
  color: var(--notif-fg);
  font-size: var(--font-size-S);
}
.notification__item .notification__message {
  color: var(--notif-fg);
  font-size: var(--font-size-S);
}
.notification__item .notification__timer {
  background-color: var(--notif-mid);
  height: 4px;
}
.notification__item .notification__timer-filler {
  background-color: var(--notif-fg);
  height: 4px !important;
}

.notification__item a {
  color: inherit;
  text-decoration: underline;
}
.notification__item--default,
.notification__item--info {
  --notif-bg: var(--primary);
  --notif-mid: var(--primary-dark);
  --notif-fg: var(--gray-6);
}
.notification__item--danger {
  --notif-bg: var(--red-bg);
  --notif-mid: var(--red-light);
  --notif-fg: var(--red);
}
.notification__item--success {
  --notif-bg: var(--green-bg);
  --notif-mid: var(--green-light);
  --notif-fg: var(--green);
}
.notification__item--warning {
  --notif-bg: var(--forget-me-not);
  --notif-mid: var(--secondary-light);
  --notif-fg: var(--secondary-dark);
}

#nav-logo {
  display: block;
  height: 40px;
}
@media (min-width: breakpoints.$sm) {
  #nav-logo {
    height: 45px;
  }
}

#innercontent {
  flex-grow: 1;
  padding: 0 15px 100px;
}

.legacy-footer {
  width: 100%;
  background-color: var(--tertiary);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  padding: 20px 12vw;

  a {
    white-space: nowrap;
    font-weight: bold;
    margin: 0.25em;
  }

  > * {
    margin: 0.5rem 20px;
  }

  a,
  .copyright {
    padding: 3px 5px;
    color: var(--gray-5);
  }

  .footer-links {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
}
@media (max-width: breakpoints.$sm) {
  .legacy-footer {
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
}
